<template>
  <v-container fluid>
    <v-layout wrap justify-center>
      <div>
      <v-card class="deodorizer_info ma-1" :loading="loading">
          <div>
            <span class="info_title mr-2">{{ device_name }}</span>
          </div>
          <div class="plc_info ml-2 mt-3">
            <div class="plc_title mr-1">위치 </div>
            <div class="plc_value">{{ room }}</div>
          </div>
          
          <div class="plc_info ml-2 mt-3">
              <div class="plc_title mr-1 ">PLC</div>
              <div class="plc_value"> {{ plc_name }} &nbsp;</div>
              <span class="plc_btn mr-2">  {{ btn_no }}
                <span>번</span>
              </span>
          </div>

          <div class="weather_info mt-2">
            <div>
              <div class="Meteorological_info" v-if="!nowweather.sensor || nowweather.sensor.pressure == null">
                <div style="font-weight:bold; font-size:18px;">기상청 정보</div>
                <div class="detail_info mt-1 ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-weather-windy-variant</v-icon>
                    풍향
                  </div>
                  <div id="deg_value" class="info_value"> {{ nowweather.wind.deg }}
                    <span class="unit"> ° | &nbsp;</span>
                  </div>
                  <div class="info_value_direction" >
                    {{ direction }}
                  </div>
                </div>

                <div class="detail_info ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-weather-windy</v-icon>
                    풍속
                  </div>
                  <div class="info_value">  {{ nowweather.wind.speed }}
                    <span class="unit">m/s</span>
                  </div>
                </div>

                <div class="detail_info ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-speedometer</v-icon>
                    기압
                  </div>
                  <div  class="info_value"> {{ nowweather.pressure }}
                    <span class="unit">hPa</span>
                  </div>
                </div>
              </div>

              <div class="Dollweather_info" v-else>
                <div style="font-weight:bold; font-size:18px;">기상센서 정보</div>

                <div class="detail_info mt-1 ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-weather-windy-variant</v-icon>
                    풍향
                  </div>
                  <div id="deg_value" class="info_value"> {{ nowweather.sensor.wind.deg }}
                    <span class="unit"> ° | &nbsp;</span>
                  </div>
                  <div class="info_value_direction" >
                    {{ sensordirection }}
                  </div>
                </div>

                <div class="detail_info ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-weather-windy</v-icon>
                    풍속
                  </div>
                  <div class="info_value">  {{ nowweather.sensor.wind.speed }}
                    <span class="unit">m/s</span>
                  </div>
                </div>

                <div class="detail_info ml-3">
                  <div class="info_title">
                    <v-icon class="mr-1" color="#3a4f3f">mdi-speedometer</v-icon>
                    기압
                  </div>
                  <div  class="info_value"> {{ nowweather.sensor.pressure }}
                    <span class="unit">hPa</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="sensor_info mt-2">
            <div style="font-weight:bold; font-size:18px;">센서정보</div>
            
            <div v-for="(refer_sensor, i) in refer_sensor" :key="i">
              <div class="detail_info mt-2">
                <div class="sensor_title mr-3">{{ refer_sensor.name }}</div>

                <div class="sensor_detail">
                  <div>{{ refer_sensor.value }}
                    <span class="unit">{{ dataUnit(i,"") }}</span>
                  </div>

                  <div class="sensor_low" style="color:blue;">{{ refer_sensor.lowest }} {{ dataUnit(i,"") }} , &nbsp; 
                    <span style="color:red;">{{ refer_sensor.highest }} {{ dataUnit(i,"") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="set_date mt-2">설정일시 : {{chgdate}}</div>
      </v-card>

      <v-row class="justify-center mt-5">
        <v-btn outlined rounded class="exit_btn mr-3" @click="exit()">이전</v-btn>
        <v-btn outlined rounded class="save_btn" :loading="loadingSave" v-if="this.$getters.userInfo.grade < 'UT06'" v-show=changed @click="save()">저장</v-btn>
      </v-row>
      </div>

      <v-form id="deodorizerSet">
      <div class="ma-5">
        <div class="standard_set">
          <div class="set_title mr-3"> 시간 기준 제어 </div>

            <v-switch dense ref="time_set" v-model="timecontrol.on" 
              style="margin-top:0px;"
              color="#3A4F3F" 
              inset>
              <template #prepend>
                <v-label><span class="set_off">OFF</span></v-label>
              </template>
              <template #append>
                <v-label><span class="set_on">ON</span></v-label>
              </template>
            </v-switch>
        </div>
        
        <div class="deodorizer_set" v-show="timecontrol.on">
          <div v-for="(timecontrol,t) in timecontrol.set" :key="t">
              <div class="setting">
                <v-layout wrap>
                    <v-text-field dense outlined 
                      type="time"
                      id="time_start"
                      ref="time_start"
                      class="set_time shrink mr-4"
                      label="시작시간"
                      v-model="timecontrol.start"
                      value="12:00"/>

                      <v-text-field dense outlined 
                      type="time"
                      id="time_finish"
                      ref="time_finish"
                      class="set_time shrink mr-4"
                      label="종료시간"
                      v-model="timecontrol.finish"
                      value="23:59"/>

                      <v-text-field dense outlined 
                      type="number"
                      id="time_run"
                      ref="time_run"
                      class="run_time shrink mr-3"
                      label="작동"
                      v-model.number="timecontrol.run"
                      min=1
                      suffix="분"/>

                      <v-text-field dense outlined 
                      type="number"
                      id="time_stop"
                      ref="time_stop"
                      class="run_time shrink mr-3"
                      v-model.number="timecontrol.stop"
                      label="중지"
                      min=0
                      suffix="분"/>

                    <div class="trash" @click="removetime(t)">
                      <v-icon color="white">mdi-delete-empty</v-icon>
                    </div>
                </v-layout>
              </div>
          </div>

          <div class="reference mr-2" align="right"> ✽ 작동을 쉬지 않을 경우 중지에 0 을 입력하세요.</div>
          
          <v-row class="justify-end mb-1 mr-1 mt-1">
            <v-btn outlined rounded class="control_btn" @click="addtime()">
              <v-icon color="#6B7B6E">mdi-plus</v-icon>
              추가</v-btn>
          </v-row>
        </div>

        <div class="standard_set mt-5">
          <div class="set_title mr-3"> 센서 기준 제어 </div>

            <v-switch dense v-model="sensorcontrol.on" 
              style="margin-top:0px;"
              color="#96B23F" 
              inset>
              <template #prepend>
                <v-label><span class="set_off">OFF</span></v-label>
              </template>
              <template #append>
                <v-label><span class="set_on">ON</span></v-label>
              </template>
            </v-switch>
        </div>

        <div class="deodorizer_set" v-show="sensorcontrol.on">
          <div v-for="(sensorcontrol,s) in sensorcontrol.set" :key="s">
              <div class="setting">
                <v-layout wrap>
                  <v-select dense outlined class="selectbox shrink mr-3"
                    id="sensor" 
                    ref="sensor" 
                    label="센서노드" 
                    v-model="sensorcontrol.sensor"
                    :items="sensorItems"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    item-text="name"
                    @change="changeSensor(s)"
                    return-object
                  />

                  <v-select dense outlined class="selectbox shrink mr-3"
                    id="data_name" 
                    ref="data_name" 
                    label="센서종류" 
                    v-model="sensorcontrol.data_name"
                    :items="datanameItems[s]"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    item-text="name"
                    @focus="comboSensorData(s)"
                    return-object
                  />

                  <v-text-field dense outlined 
                    type="number"
                    id="data_run"
                    ref="data_run"
                    class="data_time shrink mr-3"
                    label="작동"
                    v-model.number="sensorcontrol.run"
                    min=0
                    max=2000
                    :suffix="dataUnit(s,'이상')"/>

                  <v-text-field dense outlined 
                    type="number"
                    id="data_stop"
                    ref="data_stop"
                    class="data_time shrink mr-3"
                    v-model.number="sensorcontrol.stop"
                    label="중지"
                    min=0
                    max=2000
                    :suffix="dataUnit(s,'이하')"/>

                  <div class="trash mr-2" @click="removesensor(s)">
                    <v-icon color="white">mdi-delete-empty</v-icon>
                  </div>
                </v-layout>
              </div>
          </div>
          <v-row class="justify-end mb-1 mr-1  mt-1">
            <v-btn outlined rounded class="control_btn" @click="addsensor()">
              <v-icon color="#6B7B6E">mdi-plus</v-icon>
              추가
            </v-btn>
          </v-row>
        </div>

        <div class="standard_set mt-5">
          <div class="set_title mr-3" v-if="!nowweather.sensor || nowweather.sensor.pressure == null"> 기상데이터 기준 제어 </div>

          <div class="set_title mr-3" v-else> 기상센서 기준 제어 </div>

            <v-switch dense v-model="weathercontrol.on" 
              style="margin-top:0px;"
              color="#FFE081" 
              inset>
              <template #prepend>
                <v-label><span class="set_off">OFF</span></v-label>
              </template>
              <template #append>
                <v-label><span class="set_on">ON</span></v-label>
              </template>
            </v-switch>
        </div>

      <div class="deodorizer_set" v-show="weathercontrol.on">
        <div v-for="(weathercontrol,w) in weathercontrol.set" :key="w"  >
            <div class="setting">
              <v-layout wrap>
                <v-select dense outlined class="windset_time mr-2 shrink"
                    id="weather_division"
                    ref="weather_division" 
                    v-model="weathercontrol.division"
                    :items="items_weather"
                    :menu-props="{top:false, offsetY:true}"
                    label="기상종류"
                    no-data-text="권한이 없습니다."
                    item-text="name"
                    return-object/>
              
                  <v-text-field dense outlined 
                    v-show="weathercontrol.division.code  === 'pressure'"
                    type="number"
                    id="pressure_max"
                    ref="pressure_max"
                    v-model.number="weathercontrol.max"
                    class="weatherpres_set shrink"
                    label="기압기준"
                    min=900
                    max=1030
                    :rules="rulespressure"
                    suffix="hPa 이하"/>
              
                    <v-select 
                    v-show="weathercontrol.division.code  === 'deg'"
                    dense outlined class="weatherpres_set mr-2 shrink"
                    id="wind_deg"
                    ref="wind_deg" 
                    v-model="weathercontrol.deg"
                    :items="items_wind_deg"
                    :menu-props="{top:false, offsetY:true}"
                    label="풍향"
                    no-data-text="이상하네..ㅠㅠ.."
                    item-text="name"
                    return-object
                    @change="setWindValue(w)"/>
  
                    <!-- <v-text-field dense outlined 
                    v-if="weathercontrol.division.code  === 'deg'"
                    type="number"
                    id="deg_min"
                    ref="deg_min"
                    v-model.number="weathercontrol.min"
                    class="weatherdeg_set shrink mr-1"
                    label="풍향기준"
                    step=45
                    :suffix="combomindirection(w)"
                    @change="weathercontrol.min = windMask(weathercontrol.min,w)"
                    v-show=false
                    />
                    
                  <span v-show=false class="deg_unit" v-if="weathercontrol.division.code  === 'deg'"> ~ &nbsp;</span> 

                  <v-text-field dense outlined  
                    v-if="weathercontrol.division.code  === 'deg'"
                    type="number"
                    id="deg_max"
                    ref="deg_max"
                    v-model.number="weathercontrol.max"
                    class="weatherdeg_to shrink mr-2"
                    label="풍향범위"
                    step=45
                    :disabled=true
                    v-show=false
                    /> -->

                <v-text-field dense outlined 
                  type="number"
                  id="weather_run"
                  ref="weather_run"
                  class="run_time shrink mr-3"
                  v-model.number="weathercontrol.run"
                  label="작동"
                  min=1
                  max=10
                  suffix="분"/>

                <v-text-field dense outlined 
                  type="number"
                  id="weather_stop"
                  ref="weather_stop"
                  class="run_time shrink mr-2"
                  v-model.number="weathercontrol.stop"
                  label="중지"
                  min=0
                  max=10
                  suffix="분"/>
                
                <div class="trash ml-2" @click="removeweather(w)">
                  <v-icon color="white">mdi-delete-empty</v-icon>
                </div>
              </v-layout>
            </div>
          </div>

          <v-row class="justify-end mb-1 mr-1  mt-1">
            <v-btn outlined rounded class="control_btn" @click="addweather()">
              <v-icon color="#6B7B6E">mdi-plus</v-icon>
              추가
            </v-btn>
          </v-row>
        </div>

        <div class="standard_set mt-5">
          <div class="set_title mr-3"> 기후 제한 설정 </div>
        </div>

        <div class="deodorizer_set">
          <div class="setting">
            <div class="climate_stop mb-5">
              <v-row align="center">
              <span class="climate_title mr-5" style="font-weight:bold">작동 중지</span>
                <v-checkbox
                  v-model="rainstop"
                  color="#88191a"
                  label="강우 시"
                  class="checkbox mr-5"
                ></v-checkbox>

                <v-checkbox
                  v-model="snowstop"
                  color="#88191a"
                  label="강설 시"
                  class="checkbox mr-5"
                ></v-checkbox>

                <v-checkbox
                  v-model="tempstop"
                  color="#88191a"
                  label="온도 설정시"
                  class="checkbox"
                ></v-checkbox>

                <div v-if="tempstop === true">
                  <v-text-field dense outlined 
                    type="number"
                    id="templessthan"
                    ref="templessthan"
                    class="temp_stop shrink ml-5 mb-1"
                    label="중지기준온도"
                    v-model.number="templessthan"
                    suffix="°C 이하"/>
                </div>
              </v-row>
            </div>
          </div>
        </div>

      </div>
    </v-form>
    </v-layout>
  </v-container>
</template>


<script>
import Apis from '@/api/apis';
import Common from "@/utils/custom/common.js";
import dateUtil from "@/utils/custom/dateProperty.js";

export default {
    name: "DeodorizerSet",

  async created(){
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    if (!this.isNull(this.$route.params)) {
      this.infoDeodorizerSetting(this.$route.params.id);
    } else {
      this.$router.go(-1);
    }

  },
  data(){
    return{
      id : "",

      // rulesdeg:[
      //   v => !! ( v < 360 ) || '0 ~ 359 의 숫자를 입력하세요.',
      //   v => !! ( v >= 0 ) || '0~ 359 의 숫자를 입력하세요.'
      // ],

      rulespressure:[
        v => !! ( v <= 1030 ) || '900~1030의 숫자를 입력하세요.',
        v => !! ( v >= 900 ) || '900~1030의 숫자를 입력하세요.'
      ],

      device_name:"",
      direction:"",
      sensordirection :"",
      farm : {},
      room : "",
      plc_name : "",
      btn_no : "",
      refer_sensor:[],
      nowweather : {pressure: "", weather: "", wind:{deg:"",speed:""}, sensor:{pressure:"", wind:{deg:"", speed:""}} },
      chgdate : "",
      sensorItems : [],
      datanameItems: [],
      items_weather:  [{code: "pressure", name: '기압'},{code: "deg", name: '풍향'}],
      items_wind_deg: [
                        {min: 292.5, max: 337.4,  name: '↘ 북서풍'},
                        {min: 337.5, max: 22.4,  name: '↓ 북풍'},
                        {min: 22.5, max: 67.4,  name: '↙ 북동풍'},
                        {min: 67.5, max: 112.4,  name: '← 동풍'},
                        {min: 112.5, max: 157.4,  name: '↖ 남동풍'},
                        {min: 157.5, max: 202.4,  name: '↑ 남풍'},
                        {min: 202.5, max: 247.4,  name: '↗ 남서풍'},
                        {min: 247.5, max: 292.4,  name: '→ 서풍'},
                      ],
    
      timecontrol : [],
      sensorcontrol : [],
      weathercontrol : [],

      rainstop: false , 
      snowstop: false , 
      tempstop : false,
      templessthan: "",

      mac:"",
      loading: false,
      loadingSave: false,
      changed: false,

    }
  },

  watch: {
    // 변경사항 없을시 저장 비활성
    timecontrol: {
      deep: true,
      handler() {this.changed = true;}
    },
    sensorcontrol: {
      deep: true,
      handler() {this.changed = true;}
    },
    weathercontrol: {
      deep: true,
      handler() {this.changed = true;}
    },
    rainstop: {
      deep: true,
      handler() {this.changed = true;}
    },
    snowstop: {
      deep: true,
      handler() {this.changed = true;}
    },
    tempstop: {
      deep: true,
      handler() {this.changed = true;}
    },
    templessthan: {
      deep: true,
      handler() {this.changed = true;}
    },
  },


  methods:{
    // windMask(val,w) {
    //   val = Common.getWindValue(val);
    //   if (val < 315) {
    //     this.weathercontrol.set[w].max = val + 44.9;

    //   } else {  
    //     this.weathercontrol.set[w].max = val - 360 + 44.9 ;   
    //   }
    //   return val;
    // },
    dataUnit(s,tail){
      return this.sensorcontrol.set[s] &&  Common.dataUnit(this.sensorcontrol.set[s].data_name.code) + tail || "";
    },
    // combomindirection(w){
    //   return Common.getDirection(this.weathercontrol.set[w].min);
    // },
    // combomaxdirection(w){
    //   return Common.getDirection(this.weathercontrol.set[w].max);
    // },
    setWindValue(w){
      // console.log("click",this.wind_deg);
      this.weathercontrol.set[w].min = this.weathercontrol.set[w].deg.min;
      this.weathercontrol.set[w].max = this.weathercontrol.set[w].deg.max;
    },
    checkData(){
      // console.log("time=",this.timecontrol);
      // console.log("sensor=",this.sensorcontrol);
      // console.log("weather=",this.weathercontrol);

      if (this.timecontrol.on) {
        if (!this.timecontrol.set.length) { 
          alert("설정된 시간 가동조건이 없습니다");
          return "설정된 시간 가동조건이 없습니다";
        }
          
        for (let i = 0; i < this.timecontrol.set.length; i++ ) {
          if (!this.timecontrol.set[i].start || this.timecontrol.set[i].start >= this.timecontrol.set[i].finish ){
            this.$refs.time_start[i].focus();
            return "시작~종료시간을 확인하세요.";
          }
          if (this.timecontrol.set[i].run < 0 ){
            this.$refs.time_run[i].focus();
            return "가동시간을 확인하세요.";
          }
          // if (this.timecontrol.set[i].stop < 1 ){
          //   this.$refs.time_stop[i].focus();
          //   return "중지시간을 확인하세요.";
          // }
        }
      }
      
      if (this.sensorcontrol.on) {
        if (!this.sensorcontrol.set.length) { 
          alert("설정된 센서 가동조건이 없습니다");
          return "설정된 센서 가동조건이 없습니다";
        }
        for (let i = 0; i < this.sensorcontrol.set.length; i++ ) {
          if (!this.sensorcontrol.set[i].sensor || !this.sensorcontrol.set[i].sensor.mac){
            this.$refs.sensor[i].focus();
            return "센서노드를 선택하세요.";
          }
          if (!this.sensorcontrol.set[i].data_name || !this.sensorcontrol.set[i].data_name.code ){
            this.$refs.data_name[i].focus();
            return "센서종류를 선택하세요.";
          }
          if (this.sensorcontrol.set[i].run < this.sensorcontrol.set[i].stop ){
            this.$refs.data_stop[i].focus();
            return "작동 조건보다 중지 조건을 적게 입력하세요.";
          }

          if (this.sensorcontrol.set[i].run <= 0){
            this.$refs.data_run[i].focus();
            return "가동조건을 확인하세요.";
          }
          if (this.sensorcontrol.set[i].stop <= 0 ){
            this.$refs.data_stop[i].focus();
            return "중지 기준지표를 확인하세요.";
          }
        }
      }

      if (this.weathercontrol.on) {
        if (!this.weathercontrol.set.length) { 
          alert("설정된 기상 가동조건이 없습니다");
          return "설정된 기상 가동조건이 없습니다";
        }
        for (let i = 0 ; i < this.weathercontrol.set.length; i++ ) {
          if (!this.weathercontrol.set[i].division || !this.weathercontrol.set[i].division.code ){
            this.$refs.weather_division[i].focus();
            return "기상종류를 선택하세요.";
          }

          if (this.weathercontrol.set[i].division.code == "pressure") { 
            if (this.weathercontrol.set[i].max > 1030 ) {
              this.$refs.pressure_max[i].focus();
              return "기압기준을 확인하세요.";
            }
            if (this.weathercontrol.set[i].max < 900 ) {
              this.$refs.pressure_max[i].focus();
              return "기압기준을 확인하세요.";
            }
          }
          
          if (this.weathercontrol.set[i].division.code == "deg") { 
            if (!this.weathercontrol.set[i].deg.name) {
              this.$refs.wind_deg[i].focus();
              return "풍향을 선택 입력하세요.";
            }
          }
          
          if (this.weathercontrol.set[i].run < 1 ){
            this.$refs.weather_run[i].focus();
            return "가동시간을 확인하세요.";
          }
          // if (this.weathercontrol.set[i].stop < 1 ){
          //   this.$refs.weather_stop[i].focus();
          //   return "중지 기준지표를 확인하세요.";
          // }
        }
      }
      return "";
    },  
    async infoDeodorizerSetting(id){
      this.loading = true;
      this.$store.commit("resMessage","");

      await Apis.infoDeodorizerSetting(
        {
          id: id,
        },(res) => {  
            if (res.result) {
              // console.log(res.data);
              this.id = res.data.id;
              this.device_name = res.data.device_name;
              this.farm = res.data.farm;
              this.room = res.data.room;
              this.plc_name = res.data.plc_name;
              this.btn_no = res.data.btn_no;
              this.nowweather = res.data.nowweather;
              this.chgdate = res.data.chgdate;
              this.refer_sensor = res.data.sensorvalues;
              this.timecontrol = res.data.timecontrol;
              this.sensorcontrol = res.data.sensorcontrol;
              this.weathercontrol = res.data.weathercontrol;
              this.rainstop = res.data.rainstop;
              this.snowstop = res.data.snowstop;
              this.tempstop = res.data.tempstop;
              this.templessthan = res.data.templessthan;
              this.direction = Common.getDirection(res.data.nowweather.wind.deg);
              this.comboSensors();
              this.sensordirection = Common.getDirection(res.data.nowweather.sensor.wind.deg);
              for (let i = 0; i < res.data.sensorcontrol.set.length; i++ ) {
                this.datanameItems.push(
                  [ {code: "nh3", name: '암모니아'},{code: "h2s", name: "황화수소"},{code: "co2", name: '이산화탄소'},{code: "temp", name: '온도'},{code: "hum", name: '습도'},
                    {code: "bp", name: '기압'},{code: "wd", name: '풍향'},{code: "ws", name: '풍속'}]
                );
              }
              this.loading = false;
              this.$store.commit("resMessage",res.message);
          } else {  
              alert(res.message);
              this.$store.commit("resMessage",res.message);
              this.loading = false;
            }
          }
        ).catch( (err) => {  // API 오류 처리
            console.log("infoDeodorizerSetting API 호출 오류",err)
            this.$store.commit("resMessage",err);
            // alert(err);
            this.loading = false;
        }
      )
      this.changed = false;

    },
    async comboSensors() {
      await Apis.comboSensors({
        company_cd: this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        room_cd: this.room && this.room.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.sensorItems = [];
            // console.log(res.data);
            for (let i in res.data ) {
            this.sensorItems.push({
              mac: res.data[i].sensor_mac,
              name: res.data[i].device_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboSensors API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeSensor(s) {
        // 센서노드가 바뀌면 데이타네임 지우기
        this.sensorcontrol.set[s].data_name = {};
        // this.comboSensorData(s);
    },

    comboSensorData(s) {
      // console.log("s",s,this.sensorcontrol.set[s].sensor.mac)
      if (!this.sensorcontrol.set[s].sensor.mac) {
        this.$refs.sensor[s].focus();
        alert("센서노드를 먼저 선택하세요");
        this.$store.commit("resMessage","센서노드를 먼저 선택하세요");
        return;
      }

      Apis.comboSensorData({
        mac: this.sensorcontrol.set[s].sensor.mac,
        filter: true    // true: 암몸니아 황화수소 , false: 전체 센서종류
        },(res) => {  
          if (res.result) {
            this.datanameItems.splice(s,1,res.data);
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboSensorData API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    save(){
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      Apis.saveDeodorizerSetting({
        "id" : this.id,
        "timecontrol" : this.timecontrol,
        "sensorcontrol" : this.sensorcontrol,
        "weathercontrol" : this.weathercontrol,
        "rainstop": this.rainstop,
        "snowstop": this.snowstop,
        "tempstop" : this.tempstop,
        "templessthan" : this.templessthan,
        },(res) => {
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
            // this.$emit("refresh");
            this.infoDeodorizerSetting(this.id);
            // this.$router.go(-1);
          } else {
            this.loadingSave = false;
            console.log("저장 오류",res.message)
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveDeodorizerSetting API 호출 오류",err)
            alert(err);
        }
      )
    },
    removetime(t){    
      if (!confirm("시간 기준 제어 조건을 제거 하시겠습니까?")) return;
      this.timecontrol.set.splice(t, 1);
    },
    addtime(){
      this.timecontrol.set.push({"start": "00:00", "finish": "00:00", "run": 0, "stop": 0 });
    },

    removesensor(s){
      if (!confirm("센서 기준 제어 조건을 제거 하시겠습니까?"))  return;
      this.sensorcontrol.set.splice(s, 1);
      this.datanameItems.splice(s, 1);
    },

    addsensor(){
      this.sensorcontrol.set.push({ "data_name": {}, "run": 0, "sensor": {}, "stop": 0});
    },

    removeweather(w){
      if (!confirm("기상 기준 제어 조건을 제거 하시겠습니까?"))  return;
      this.weathercontrol.set.splice(w, 1);
    },
    addweather(){
      this.weathercontrol.set.push({"division": {}, "max": 0, "min": 0, "run": 0, "stop": 0, "deg": {"min": null, "max": null, "name": null } });
    },
    exit(){
      this.$router.go(-1);
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__suffix {
  font-size: 11px;
  letter-spacing: -1px;
  width: 95px;
}
.deodorizer_info{
  width:380px;
  border-radius: 10px;
  padding:18px;
  // border: 2px solid #3a4f3f;
}
.plc_info{
  display:flex;
  height:25px;
  line-height:25px;
}

.plc_title{
  font-size: 14px;
  // font-weight:bold;
  width: 35px;
}

.plc_btn{
  background-color: #f0f2f5;
  color:gray;
  border-radius: 10px;
  font-size: 12px;
  font-weight:bold;
  width:35px;
  text-align: center;
}
.plc_position{
  color:rgb(122, 122, 122);
  font-size: 14px;
}
.plc_value{
  font-weight:bold;
  letter-spacing: -1.5px;
  font-size: 15px;
}
.weather_info, .sensor_info{
  background-color: #f0f2f5;
  border-radius: 10px;
  padding:15px;
}

.detail_info{
  display:flex;
  padding:4px;
}
.info_value_direction{
  font-size:17px;
  // margin-top:1px;
}

.info_title{
  font-size:18px;
  font-weight:bold;
  letter-spacing: -1.5px;
  width:110px;
}

.info_value{
  font-size:17px;
}

.unit{
  font-size:15px;
}

.sensor_title{
  font-size:14px;
  letter-spacing: -1.5px;
  font-weight:bold;
  width:135px;
  // height:50px;
}

.sensor_detail{
  display:block;
  padding-bottom: 2px;
}

.sensor_low{
  font-size:12px;
  margin-top:-5px;
  letter-spacing:0px;
}

.set_date{
  font-size:13px;
  text-align: right;
  color:#969393;
}

.standard_set{
  display:flex;
}

.set_title{
  font-size:20px;
  font-weight:bold;
}

.set_off, .set_on{
  font-size:13px;
}

.set_on{
  margin-left: -20px;
}

.set_time{
  width:195px; 
}
.selectbox{
  width:170px;
}

.data_time{
  width:118px;
}
.run_time{
  width:90px;
}

.deodorizer_set{
  box-shadow: 0 1px 1px #a3a3a3;
  border-radius: 10px;
  // border: 1px solid #3a4f3f;
  height:100%;
  padding: 10px 5px 5px 10px;
}

.reference{
  font-size:13px;
  color:#a3a3a3;
}

.setting{
  margin-bottom:-15px;
  width:700px;
  // border: 1px solid #3a4f3f;
}

.trash{
  background-color: #a3a3a3 !important;
  border-radius: 10px;
  cursor: pointer;
  margin-top:3px;
  width:30px !important;
  height:35px !important;
  line-height: 35px;
  text-align: center;
}

.windset_time{
  width:110px;
.windset_deg{
  width:260px;
}
}
.weatherpres_set{
  width:270px;
  // margin-left:10px !important;
  margin-right:8px !important;
  
}

.weatherdeg_set{
  width:130px;
}
.weatherdeg_to{
  width:123px;
}

.deg_unit{
  height: 40px;
  line-height: 40px;
  margin-right:-5px;
}

.control_btn{
  font-size:17px;
  width:80px;
  height:40px !important;
  color:#3a4f3f;
  font-weight:bold;
  justify-items: end;
  background-color: white;
}

.exit_btn{
  font-size:18px;
  font-weight:bold;
  border-color: #3a4f3f;
  color:#3a4f3f;
  height:50px !important;
  width:120px !important;

}

.save_btn{
  font-size:18px;
  font-weight:bold;
  color:white;
  background-color: #3a4f3f;
  height:50px !important;
  width:120px !important;
}

.climate_stop{
  padding:5px 0px 0px 25px;
  margin:5px;
  height:55px;
  border:1px solid #9e9e9e;
  border-radius:10px;
  font-size:17px;
}

.temp_stop{
  height:35px;
  width: 170px;
}

@media screen and (max-width: 768px){
  .setting{
    border: 1px solid #a3a3a3;
    border-radius: 20px;
    padding:20px;
    margin:10px;
    width:250px;
  }

  .set_time, .selectbox, .windset_time{
    width:260px;
  }

  .run_time{
    width:100px;
  }

  .data_time{
    width:110px;
  }

  .weatherpres_set{
    width:250px;
    margin-left:3px !important;
  }
  .weatherdeg_set{
    width:120px;
  }
  
  .trash{
    margin-left: -6px;
  }

  .climate_stop{
    height:160px;
    padding:15px 0px 0px 20px;
  }

  .climate_title{
    width:200px;
  }

  .temp_stop{
    width:120px;
  }


}

</style>